.play-scorebar-container {
  display: flex;
  background-color: $white;
  border-left: 1px solid $black;
  border-right: 1px solid $black;
  border-bottom: 1px solid $black;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  justify-content: center;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  .inner-container {
    min-width: 30%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    p {
      @include font-size($font-size-sm * 1.5 !important);
      font-family: $bold-font;
    }

    @media (max-width: 768px) {
      min-width: 100%;
    }
  }
}

.entered-word-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 1px;
  margin-top: 18px;
  margin-bottom: 30px;
  .entered-word {
    font-size: $font-size-sm * 2.5 !important;
    font-family: $bold-font;
    text-align: center;
  }

  .horizontal-line {
    border: none;
    height: 1px;
    background-color: $black;
    opacity: 1;
    width: 100%;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: -5px;
  }

  @media (max-width: 768px) {
    .entered-word-container {
    }
    .entered-word {
      font-size: $font-size-sm * 2.5 !important;
    }
  }
}
