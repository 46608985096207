.play-btn-container {
  min-width: 320px;
  justify-content: center;
  align-self: center;
  margin-top: $base-margin * 6;
}
.title {
  @include font-size($font-size-xxl * 4 !important);
  margin-top: $base-margin * 8;
}
.title {
  @include font-size($font-size-xxl !important);
  margin-top: $base-margin * 8;
}

.promo-text {
  @include font-size($font-size-sm * 1.1 !important);
  margin-top: $base-margin * 2;
}
.btn-ok {
  background-color: $green !important;
  font-size: $font-size-sm * 1.3 !important;
  color: $white;
  border-color: $green !important;

  &:hover {
    background-color: mix($black, $green, 10) !important;
    color: $white;
    text-decoration: none;
  }
}
.btn-ok-label {
  @include font-size($base-font-size !important);
}
.game-rule-text {
  @include font-size($font-size-lg !important);
}
.centered-div {
  width: 100%;
  margin: 0 auto; /* This centers the div horizontally */
  text-align: center; /* This centers the text inside the div */
  display: flex;
  flex-direction: column;
  align-items: center; /* This centers child elements vertically */
  justify-content: center; /* This centers child elements horizontally */
}
@media (min-width: 768px) {
  .centered-div {
    width: 100%;
  }
}
.icon-size {
  width: 20px;
  height: 20px;
  @media (min-width: 768px) {
    .icon-size {
      width: 10px;
      height: 10px;
    }
  }
}
.btn-text-size {
  font-size: 10px !important;
  @media (min-width: 768px) {
    .btn-text-size {
      font-size: 10px !important;
    }
  }
}

.promotion-txt {
  font-size: 16px !important;
}
