.game-over-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 50%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: $base-margin * 2;
  height: 50%;

  @media (max-width: 768px) {
    width: 90%; /* Adjust for smaller screens */
    padding: 15px;
  }

  .game-over-card-info {
    margin-bottom: 20px;

    width: 50%;
    .words-container {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      width: 100%;
    }

    .info-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      p {
        margin: 0;
      }
    }
  }
}
