.toast {
  position: fixed;
  top: 60px;
  right: 20px;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  padding: 10px 20px;
  opacity: 0;
  transition: opacity 0.3s;

  &.show {
    opacity: 1;
  }

  .toast-message {
    font-size: 16px;
  }
}
