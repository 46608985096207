.radio-buttons-row {
  .form-check {
    .form-check-label {
      white-space: nowrap;
      overflow: hidden;
      font-weight: 400;

      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
}
