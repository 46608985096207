.letters-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 auto; /* Center horizontally */
  margin-bottom: 10px;
  max-width: 600px;
  height: calc(10.5 * (30px + 10px)); /* Adjust based on row height and gap */
  overflow: hidden; /* Hide overflow if more rows than visible */

  @media (max-width: 768px) {
    height: calc(8 * (30px + 10px));
  }
}

.letters-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center; /* Center vertically */
  gap: 5px;
  margin-left: 25px;
  .letters-item {
    flex: 1 1 calc(25% - 10px);
    max-width: calc(25% - 10px);
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 30px;
    text-align: center;
    cursor: pointer;
    background-color: $primary;
    font-size: 36px;
    font-family: $bold-font;
    transition: background-color 0.3s ease;

    // &:hover {
    //   background-color: mix($primary, $white, 70%);
    // }
    &:active {
      background-color: $green;
    }

    @media (max-width: 768px) {
      /* Adjust based on row height and gap */
      touch-action: auto;
      padding: 15px;
      font-size: 32px;
    }
  }
}
