// import google font
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* reset styles */

* {
  color: inherit;
  margin: 0;
}

body {
  font-family: Poppins;
}

ul {
  padding: 0;
  list-style-type: none;
}

a {
  text-decoration: none;
}

hr {
  border: 0;
  border-top: 1px dotted #efefef;
}

img {
  max-width: 100%;
}

.test {
  color: $primary;
}
h2,
h2 {
  font-size: clamp(5rem, 4rem + 10vw, 6rem);
}
