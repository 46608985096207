%flex-layout {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.navbar {
  @extend %flex-layout;
  padding-left: $base-padding * 2 !important;
  padding-right: $base-padding * 2 !important;

  box-shadow: $base-box-shadow;
  background-color: $secondary;
  .site-title {
    font-size: $font-size-lg;
    color: $white;
  }
  .navbar-icon {
    color: $white;
    font-size: 24px;
  }
}
