.radio-buttons-row {
  // width: 90%;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  padding: 10px;
  // margin: 0 auto;

  .form-check {
    display: flex;
    align-items: center;
    .form-check-label {
      margin-left: 8px; // Adjust the spacing between the radio button and label
    }
  }
}
