/* Import any additional SCSS frameworks or reset styles here */

/* Styling for the modal background */
.modal-backdrop.show {
  opacity: 0.7; /* Adjust as needed */
}

/* Styling for the modal content */
.modal-content {
  width: 90%; /* Adjust as needed */
  max-width: 500px; /* Adjust as needed */
  padding: 0px; /* Adjust as needed */
  background-color: transparent !important;
  border: none !important;
}

.end-game-modal-content {
  background-color: $white !important;
  padding: $base-padding;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.game-over-modal-content {
  background-color: $white !important;
  padding: $base-padding;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.end-game-modal-cont {
  background-color: $white !important;
  padding: $base-padding;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 200px !important;
}

/* Styling for the modal body */
.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* Styling for the buttons container */
.end-game-modal-btn-container {
  display: flex;
  justify-content: space-between;
  padding-top: 20px; /* Adjust as needed */
  background-color: $white !important;
  padding: $base-padding;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* Styling for the buttons */

/* Styling for the buttons */
.end-game-modal-btn-container {
  & .btn-yes,
  & .btn-no {
    color: white;
    width: 48%; /* Adjust as needed to create a little gap between buttons */
    border: none;
    padding: 10px 20px; /* Add padding to the button */
    font-family: $bold-font;

    @media (max-width: 768px) {
      /* Adjust font size for smaller screens */
      font-size: 20px;
      font-family: $bold-font;
    }

    @media (max-width: 576px) {
      /* Further adjust font size for even smaller screens */
      font-size: 20px;
      font-family: $regular-font;
    }
  }

  & .btn-yes {
    background-color: $green !important;

    &:hover {
      background-color: mix($green, white, 80%) !important; /* Hover color */
    }
  }

  & .btn-no {
    background-color: $secondary !important;

    &:hover {
      background-color: mix(
        $secondary,
        white,
        80%
      ) !important; /* Hover color */
    }
  }
}

.custom-modal-body {
  background-color: transparent !important; /* Set modal background color to transparent */
  border: none !important; /* Remove modal border */
  box-shadow: none !important; /* Remove modal box shadow */
  padding: 0 !important;

  &.fade {
    opacity: 1 !important; /* Keep modal content visible */
    transform: none !important; /* Disable default animation */
  }
}

.modal-cross {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 250px;
  color: red;
  height: 400px; /* Set the height of the cross container */
  width: 400px; /* Set the width of the cross container */

  margin: 0 auto; /* Center the cross horizontally */

  @media (max-width: 768px) {
    font-size: 80px; /* Decrease font size for smaller screens */
    height: 80px; /* Adjust height for smaller screens */
    width: 80px; /* Adjust width for smaller screens */
  }
}
