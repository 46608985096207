/* Loading.css */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(
    0,
    0,
    0,
    0.6
  ); /* Light grey background with some transparency */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it's on top of other content */
}

.loading-spinner {
  padding: 20px;
  border-radius: 8px;
}
