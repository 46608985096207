.table-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-scores-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid $lightGray;
  border-radius: 8px;
  overflow: hidden;
  table-layout: fixed; /* Add this property to ensure equal column width */

  th,
  td {
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid $lightGray;
    white-space: nowrap; /* Prevent long names from wrapping */

    font-size: 10px;
    /* Set the width for all columns */
    width: 25%; /* You can adjust this value based on your preference */
  }

  th {
    background-color: $lightWhite;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    font-size: 14px;

    th,
    td {
      padding: 5px;
    }
  }
}
.dictionary-color {
  width: 20px !important;
  height: 15px;
  border-width: 1px;
  border-color: black;
  margin-left: 2px;
  margin-right: 2px;
}
