.container-help {
  padding-left: $base-padding;
  padding-right: $base-padding;
}
.help-text-regular {
  font-family: $regular-font;
}
.help-text-bold {
  font-family: $bold-font;
}
.help-text-list {
  list-style-type: disc;
  padding-left: 30px; /* Adjust the padding as needed */
}
.unfilled-bullet-list {
  list-style: none; /* Remove default list styles */
  padding-left: 0; /* Remove default left padding for the list */

  li::before {
    content: "○"; /* Unicode character for a circle (unfilled bullet point) */
    margin-right: 8px; /* Adjust the space between the bullet point and the text */
  }
}
