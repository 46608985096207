@mixin btn($bg-color: #e2e2e2) {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  padding: $base-padding $base-padding * 2;
  border-radius: $base-border-radius;
  background-color: $bg-color;
}

.btn {
  @include btn;
}

@each $key, $val in $colors {
  .btn-#{$key} {
    @include btn($val);
    &:hover {
      background-color: lighten($val, 5);
    }
  }
  .btn-outlined-#{$key} {
    @include btn(lighten($val, 9));
    // padding: $base-padding $base-padding;
    border: $base-border-thickness solid $val;
    &:hover {
      background-color: $val;
    }
    @media (max-width: 768px) {
      padding: $base-padding * 0.2 $base-padding !important;
    }
  }
  .btn-complement-#{$key} {
    @include btn($val);
    color: light-comp($val);
    &:hover {
      color: $val;
      background-color: light-comp($val);
    }
  }
}

.btn-icon {
  width: $icon-width;
  height: $icon-height;
}
.btn-label {
  @include font-size($font-size-sm !important);
  margin-top: $base-margin;
}
.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 2.5%;

  .action-buttons {
    display: flex;
    align-items: center;
    width: 48%; /* Occupy the same space as the old "Delete" button */
    justify-content: space-between;

    .custom-button {
      width: 48%;
      padding: 12px;
      font-size: 18px;
      border: none;
      cursor: pointer;
      transition: background-color 0.3s ease, opacity 0.3s ease;
      height: 60px;

      &.delete-button {
        background-color: $secondary;
        color: white;
        height: 100px;
        font-size: 24px;
      }

      &.clear-button {
        background-color: $secondary;
        color: white;
        height: 100px;
        font-size: 24px;
      }

      &:hover {
        background-color: lighten($secondary, 5);
        opacity: 0.9;
      }

      &:active {
        background-color: lighten($secondary, 5);
        opacity: 1;
      }

      &:disabled {
        transition: background-color 0.3s ease, opacity 0.3s ease;
        background-color: #37475e !important;
      }

      @media (max-width: 768px) {
        width: 48%;
        margin-bottom: 10px;
        padding: 10px;
        font-size: 16px;
        height: 75px;

        &:hover {
          background-color: lighten($secondary, 0);
          opacity: 1;
        }
      }
    }
  }

  .submit-button {
    width: 45%; /* Keep the Submit button's width */
    padding: 12px;
    font-size: 24px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, opacity 0.3s ease;
    height: 100px;

    background-color: $secondary;
    color: white;
    font-size: 24px;

    &:hover {
      background-color: lighten($secondary, 5);
      opacity: 0.9;
    }

    &:active {
      background-color: lighten($secondary, 5);
      opacity: 1;
    }

    &:disabled {
      transition: background-color 0.3s ease, opacity 0.3s ease;
      background-color: #37475e !important;
    }

    @media (max-width: 768px) {
      width: 45%;
      margin-bottom: 10px;
      padding: 10px;
      font-size: 24px;
      height: 100px;

      &:hover {
        background-color: lighten($secondary, 0);
        opacity: 1;
      }
    }
  }
}

.button-group {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 50%;
  .custom-button {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px; // Adjust the height as needed
    background-color: $secondary; // Change the background color as needed
    color: #fff; // Text color
    border: none;
    border-radius: 10px;
    margin-left: 5px;
    margin-right: 5px;

    .fa {
      font-size: 24px;
      margin-bottom: 5px;
    }

    .button-label {
      font-size: 12px;
      font-family: $bold-font;
    }

    &:hover {
      background-color: $manatee;
    }
    &:active {
      background-color: $manatee !important;
    }

    @media (max-width: 768px) {
      width: 100px;
      height: 75px;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    .button-label {
      font-size: 10px;
    }
  }
}

.btn-friend-grp {
  width: 100px !important;
  font-size: 16px !important;
  font-family: $bold-font;
}
.btn-okay {
  width: 100px !important;
  font-size: 16px !important;
  font-family: $bold-font;
  border-radius: 0px !important;
}

.disabled-button {
  background: gray !important;
  color: white !important;
  border-color: gray !important;
}
