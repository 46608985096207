.options-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  h3 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .tick-icon {
    margin-left: 5px;
  }

  .game-of-the-day-btn {
    margin-top: 20px;
  }

  .btn-group {
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    .btn {
      flex-grow: 1;
    }
  }

  @media (max-width: 768px) {
    .options-container {
      padding: 10px;
    }
  }
}
