@font-face {
  font-family: "DMSans-Regular";
  src: url("../Assets/fonts/DMSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-Bold";
  src: url("../Assets/fonts/DMSans-Bold.ttf") format("truetype");
}
// theme colors
$primary: rgb(35, 196, 237) !default;
$secondary: #37475e !default;
$green: #27ae61;
$manatee: #8d90a1;
$warning: #fada5e;
$navbar: #34475d;
$white: #fff;
$black: #000;
$error: #ff5733;
$lightGray: #ccc;
$lightWhite: #f2f2f2;

// color palette
$colors: (
  "primary": $primary,
  "secondary": $secondary,
  "green": $green,
  "warning": $warning,
  "navbar": $navbar,
  "manatee": $manatee,
  "white": $white,
  "black": $black,
  "error": $error,
  "lightGray": $lightGray,
  "lightWhite": $lightWhite,
) !default;

// spacing
$base-padding: 0.75rem !default;
$base-margin: 0.75rem !default;

// borders
$base-border-thickness: 1px !default;
$base-border-radius: 20px !default;

// box-shadow
$base-box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1) !default;

// font sizes
$base-font-size: 1rem !default;
$font-size-sm: $base-font-size * 0.75 !default;
$font-size-lg: $base-font-size * 1.5 !default;
$font-size-xl: $base-font-size * 2 !default;
$font-size-xxl: $base-font-size * 5 !default;

$icon-width: 2rem;
$icon-height: 2rem;

$regular-font: "DMSans-Regular";
$bold-font: "DMSans-Bold";

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);
