.my-scores-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.scores-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%; /* Adjust the width as needed */
  border: 1px solid #ccc; /* Add border */
  border-radius: 8px;
  padding: 10px;
  margin-top: 20px;
}

.list-item {
  border-bottom: 1px solid #ccc; /* Add bottom border for each item */
  padding: 10px;
  width: 100%;
  text-align: left;
}

.info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
}

.info-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.info {
  display: flex;
  align-items: center;
}

.info-label {
  font-weight: bold;
  margin-right: 5px;
}

@media (max-width: 768px) {
  .scores-list {
    width: 90%; /* Adjust the width for smaller screens */
  }
}
