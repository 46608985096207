.word-game {
  max-width: 103px;
  margin: auto;
  font-family: Arial, sans-serif;

  @media (max-width: 576px) {
    max-width: 105px;
    margin: auto;
  }
}
.slide-parent {
  max-width: 100px;
  display: flex;
  margin: 0;
  align-items: center;
  justify-content: center;
  @media (max-width: 576px) {
    max-width: 100px;
  }
}

.consonants-text {
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  height: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px; /* Add padding for space between slides */
  padding-right: 10px; /* Add padding for space between slides */
}

.bgManatee {
  background-color: $manatee !important;
}

.bgGray {
  background-color: #ccc !important;
}

.consonants-text p {
  color: white;
  font-weight: bold;
  margin: 0; /* Remove any default margin */
  display: flex; /* To center text */
  align-items: center; /* To center text */
  justify-content: center; /* To center text */
  text-align: center;
}

.most-vowels {
  font-size: 14px;
  @media (max-width: 576px) {
    font-size: 14px;
  }
}
.custom-slider .MuiSlider-thumb {
  width: 12px; /* Adjust the width */
  height: 12px; /* Adjust the height */
}
